<template>
  <div class="ImportCertificate">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >组别代码：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="groupCode"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >组别名称：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="likeGroupName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >代表队：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="likeTeamName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >背号：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="playerNo"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >选手姓名：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="likePlayerName"
          ></el-input>
        </div>
        <div class="btn2">
          <el-button
            style="height: 40px; margin-right: 10px"
            type="primary"
            plain
            @click="searchSchedule"
            >查询</el-button
          >
          <el-upload
            :action="urls.importCertificate"
            name="file"
            :headers="{
              Authorization: stores.state.token,
            }"
            :data="{ competitionId: this.competitionId }"
            :multiple="false"
            :show-file-list="false"
            :on-success="uploadFileSuccess"
            :on-error="uploadFileSuccess"
            list-type="文件类型列表"
          >
            <el-button
              style="height: 40px; margin-right: 10px"
              type="primary"
              plain
              >导入</el-button
            >
          </el-upload>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="exportTable"
            >模板下载</el-button
          >
          <el-button style="height: 40px" type="primary" plain @click="empty"
            >清空证书</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="背号">
            <template slot-scope="scope">
              <span>{{ scope.row.playerNo }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选手信息">
            <template slot-scope="scope">
              <span>{{ scope.row.playerInfo }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选手姓名">
            <template slot-scope="scope">
              <span>{{ scope.row.playerNames }}</span>
            </template>
          </el-table-column>
          <el-table-column label="代表队编号">
            <template slot-scope="scope">
              <span>{{ scope.row.teamCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="代表队名称">
            <template slot-scope="scope">
              <span>{{ scope.row.teamName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别代码">
            <template slot-scope="scope">
              <span>{{ scope.row.groupCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称">
            <template slot-scope="scope">
              <span>{{ scope.row.groupName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="名次">
            <template slot-scope="scope">
              <span>{{ scope.row.rankingName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="人数/证书数">
            <template slot-scope="scope">
              <span>{{ scope.row.certificateNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="奖杯数/奖牌数/奖贴数">
            <template slot-scope="scope">
              <span>{{ scope.row.medalNum }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import schedule from "../api/schedule";
import store from "../store";
export default {
  data() {
    return {
      urls: schedule.URLs,
      stores: store,
      total: 10.0,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      competitionId: this.$route.params.id,
      groupCode: "",
      likeGroupName: "",
      likeTeamName: "",
      playerNo: "",
      likePlayerName: "",
      playerName: "",
      tableData: [],
    };
  },
  created() {
    this.querySchedule();
  },
  methods: {
    //下载证书模板
    exportTable() {
      window.location.href =
        "http://asset.idance5.com/download/template/competition/%E8%AF%81%E4%B9%A6%E6%89%93%E5%8D%B0.xls";
    },
    //导入证书后的回调
    uploadFileSuccess(callBackData) {
      const { status_code, msg } = callBackData;
      console.log(callBackData);
      if (status_code === "200") {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.querySchedule();
      } else if (status_code == "1002") {
        this.$message({
          type: "warning",
          message: msg,
        });
      }
    },
    //获取查询所有证书信息
    async querySchedule() {
      try {
        const result = await this.$api.queryCertificate(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&competitionId=${this.competitionId}&groupCode=${this.groupCode}&likeGroupName=${this.likeGroupName}&likeTeamName=${this.likeTeamName}&playerNo=${this.playerNo}&likePlayerName=${this.likePlayerName}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //获取查询所有证书信息
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.queryCertificate(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&competitionId=${this.competitionId}&groupCode=${this.groupCode}&likeGroupName=${this.likeGroupName}&likeTeamName=${this.likeTeamName}&playerNo=${this.playerNo}&likePlayerName=${this.likePlayerName}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //清空所有证书信息
    empty() {
      this.$confirm("是否清空所有证书?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.clearCertificate(
            `competitionId=${this.competitionId}`
          );
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "清除成功",
            });
            this.querySchedule();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.querySchedule();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.querySchedule();
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less">
.ImportCertificate {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 10px;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          font-size: 20px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
