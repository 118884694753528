<template>
  <div class="bar">
    <el-card class="box-card">
      <!-- <div v-for="(item,index) in list" :key="index" class="text item">
        {{ item.name }}
        <span class="info">{{item.content}}</span>
      </div> -->
      <div class="text item">
        赛事id(用于计分对接)
        <span class="info">{{ tableData.id }}</span>
      </div>
      <div class="text item">
        赛事名称
        <span class="info">{{ tableData.name }}</span>
      </div>
      <div class="text item">
        场馆名称
        <span class="info">{{ tableData.buildingName }}</span>
      </div>
      <div class="text item">
        场馆地址
        <span class="info">{{ tableData.address }}</span>
      </div>
      <div class="text item">
        比赛日期
        <span class="info">{{
          $moment(tableData.startTime).format('YYYY-MM-DD')
        }}</span
        ><span class="end"
          >~{{ $moment(tableData.endTime).format('YYYY-MM-DD') }}</span
        >
      </div>
      <div class="text item">
        报名截止
        <span class="info">{{
          $moment(tableData.applyEndTime).format('YYYY-MM-DD HH:mm')
        }}</span>
      </div>
      <div class="text item">
        报到日期
        <span class="info">{{
          $moment(tableData.registerStartTime).format('YYYY-MM-DD')
        }}</span
        ><span class="end">~{{
          $moment(tableData.registerEndTime).format('YYYY-MM-DD')
        }}</span>
      </div>
      <div class="text item">
        报到地址
        <span class="info">{{ tableData.registerAddress }}</span>
      </div>
      <div class="text item">
        已报人数
        <span class="info">{{ tableData.appliedCount }}</span>
      </div>
      <div class="text item">
        已报组别数
        <span class="info">{{ tableData.appliedGroupCount }}</span>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'detail',
  data() {
    return {
      tableData: {},
    }
  },
  props: ['id'],
  created() {
    this.getTableData()
  },
  methods: {
    //获取基本信息
    async getTableData() {
      try {
        const result = await this.$api.baseInfo(`id=${this.id}`)
        this.tableData = result
        console.log(this.tableData)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.bar {
  position: relative;
  .info {
    position: absolute;
    left: 200px;
  }
  .end {
    position: absolute;
    left: 280px;
  }
  .text {
    font-size: 14px;
  }

  .item {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
}
</style>
