<template>
  <div class="Schedule">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >日期：</span
          >
          <el-col :span="3">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="runDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 200px"
            ></el-date-picker>
          </el-col>
          <span style="height: 40px; line-height: 40px; margin-left: 30px"
            >场序：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="mpName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >组别代码：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="groupCode"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >组别名称：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="groupName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >是否首轮：</span
          >
          <el-select v-model="isFirstTurn" placeholder="请选择" class="select1">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="btn2">
          <el-button
            style="height: 40px; margin-right: 10px"
            type="primary"
            plain
            @click="searchSchedule"
            >查询</el-button
          >
          <el-upload
            :action="urls.importSchedule"
            name="file"
            :headers="{
              Authorization: stores.state.token,
            }"
            :data="{ competitionId: this.competitionId }"
            :multiple="false"
            :show-file-list="false"
            :on-success="uploadFileSuccess"
            :on-error="uploadFileSuccess"
            list-type="文件类型列表"
          >
            <el-button
              style="height: 40px; margin-right: 10px"
              type="primary"
              plain
              >导入</el-button
            >
          </el-upload>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="exportTable"
            >模板下载</el-button
          >
          <el-button style="height: 40px" type="primary" plain @click="empty"
            >清空总赛程</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="日期">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.runDate).format("YYYY-MM-DD") }}</span>
            </template>
          </el-table-column>
          <el-table-column label="场序">
            <template slot-scope="scope">
              <span>{{ scope.row.mpName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="区域">
            <template slot-scope="scope">
              <span>{{ scope.row.areaName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否首轮">
            <template slot-scope="scope">
              <span>{{ options[scope.row.isFirstTurn].label }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开始时间">
            <template slot-scope="scope">
              <span>{{ scope.row.runTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别代码">
            <template slot-scope="scope">
              <span>{{ scope.row.groupCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称">
            <template slot-scope="scope">
              <span>{{ scope.row.groupName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="分组">
            <template slot-scope="scope">
              <span>{{ scope.row.groupDesc }}</span>
            </template>
          </el-table-column>
          <el-table-column label="轮次">
            <template slot-scope="scope">
              <span>{{ scope.row.turnName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="舞种">
            <template slot-scope="scope">
              <span>{{ scope.row.danceType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参赛数">
            <template slot-scope="scope">
              <span>{{ scope.row.totalNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="录取数">
            <template slot-scope="scope">
              <span>{{ scope.row.admissionNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别类型">
            <template slot-scope="scope">
              <span>{{ scope.row.groupType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="赛制">
            <template slot-scope="scope">
              <span>{{ scope.row.raceType }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" width="350">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)"
                >详情</el-button
              >
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >发送报名邮件</el-button
              >
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >发送赛程邮件</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import schedule from "../api/schedule";
import store from "../store";
export default {
  data() {
    return {
      urls: schedule.URLs,
      stores: store,
      groupName: "",
      total: 10.0,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      competitionId: this.$route.params.id,
      runDate: "",
      mpName: "",
      groupCode: "",
      likeGroupName: "",
      isFirstTurn: "",
      options: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      playerName: "",
      tableData: [],
    };
  },
  created() {
    this.querySchedule();
  },
  methods: {
    //下载总赛程模板
    exportTable() {
      window.location.href =
        "http://asset.idance5.com/download/template/competition/%E8%B5%9B%E7%A8%8B.xls";
    },
    //导入赛程后的回调
    uploadFileSuccess(callBackData) {
      const { status_code, msg } = callBackData;
      console.log(callBackData);
      if (status_code === "200") {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.querySchedule();
      } else if (status_code == "1002") {
        this.$message({
          type: "warning",
          message: msg,
        });
      }
    },
    //发送邮件
    handleDelete(index, row) {
      console.log(index, row);
    },
    //总赛程详情
    handleDetail() {},
    handleSizeChange(val) {
      this.pageSize = val;
      this.querySchedule();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.querySchedule();
    },
    //获取总赛程信息
    async querySchedule() {
      try {
        const result = await this.$api.querySchedule(
          `currentPage=${this.currentPage}&pageSize=${
            this.pageSize
          }&competitionId=${this.competitionId}&runDate=${
            this.runDate ? this.runDate : ""
          }&mpName=${this.mpName}&groupCode=${this.groupCode}&likeGroupName=${
            this.groupName
          }&isFirstTurn=${this.isFirstTurn}`
        );
        console.log(result, "````aaaa");
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //查询总赛程信息
    async searchSchedule() {
      this.pageSize = 10;
      this.currentPage = 1;
      try {
        const result = await this.$api.querySchedule(
          `currentPage=${this.currentPage}&pageSize=${
            this.pageSize
          }&competitionId=${this.competitionId}&runDate=${
            this.runDate ? this.runDate : ""
          }&mpName=${this.mpName}&groupCode=${this.groupCode}&likeGroupName=${
            this.groupName
          }&isFirstTurn=${this.isFirstTurn}`
        );
        console.log(result, "````aaaa");
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //清空总赛程信息
    empty() {
      this.$confirm("是否清除所有总赛程信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.clearSchedule(
            `competitionId=${this.competitionId}`
          );
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "清除成功",
            });
            this.querySchedule();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    // empty
  },
};
</script>

<style lang="less">
.Schedule {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 200px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
      .btn2 {
        display: flex;
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 10px;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          font-size: 20px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
