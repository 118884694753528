<template>
  <div class="RegistrationUnit">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >单位名称：</span
          >
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="teamName"
          ></el-input>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleSearch"
            >搜索</el-button
          >
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleExportUnit"
            >导出单位</el-button
          >
          <!-- <el-button style="height:40px" type="primary" plain disabled
            >统一发送报名邮件</el-button
          >
          <el-button style="height:40px" type="primary" plain disabled
            >统一发送赛程邮件</el-button
          > -->
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="单位名称">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位负责人姓名">
            <template slot-scope="scope">
              <span>{{ scope.row.contactName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位负责人电话">
            <template slot-scope="scope">
              <span>{{ scope.row.contactPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="邮箱">
            <template slot-scope="scope">
              <span>{{ scope.row.email }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位详细地址">
            <template slot-scope="scope">
              <span>{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" width="350">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)"
                >详情</el-button
              >
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >发送报名邮件</el-button
              >
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >发送赛程邮件</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      total: 10.0,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      teamName: "",
      tableData: [],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    //搜索报名单位信息
    async handleSearch() {
      this.pageSize = 10;
      this.currentPage = 1;
      try {
        const result = await this.$api.findApplyUnit(
          `search_LIKE_teamName=${this.teamName}&search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}`
        );
        this.tableData = result.pageResults;
        this.total = result.totalCount;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //报名单位详情
    handleDetail(index, row) {
      console.log(index, row);
      this.$router.push({
        name: "UnitDetail",
        params: {
          competitionId: this.id,
          unitId: row.id,
          back: row.username,
          name: "1",
        },
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
      console.log(`当前页: ${val}`);
    },
    //获取所有报名单位信息
    async getTableData() {
      try {
        const result = await this.$api.findApplyUnit(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.total = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //导出报名单位信息表
    async handleExportUnit() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportApplyUnit(
          `search_EQ_competitionId=${this.id}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.RegistrationUnit {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          font-size: 20px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
