<template>
  <div class="Invoice">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <!-- <span style="height:40px;line-height:40px;margin-left:10px"
            >单位名称：</span
          >
          <el-input
            style="margin-right:20px;width:180px"
            v-model="teamName"
          ></el-input> -->
          <!-- <el-button
            style="height:40px"
            type="primary"
            plain
            @click="handleSearch"
            >搜索</el-button
          > -->
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleExportUnit"
            >导出发票申请信息</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="用户名">
            <template slot-scope="scope">
              <span>{{ scope.row.appUserName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="电话">
            <template slot-scope="scope">
              <span>{{ scope.row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="电子邮箱">
            <template slot-scope="scope">
              <span>{{ scope.row.email }}</span>
            </template>
          </el-table-column>
          <el-table-column label="发票抬头">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税号">
            <template slot-scope="scope">
              <span>{{ scope.row.code }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开户银行">
            <template slot-scope="scope">
              <span>{{ scope.row.bank }}</span>
            </template>
          </el-table-column>
          <el-table-column label="银行账号">
            <template slot-scope="scope">
              <span>{{ scope.row.account }}</span>
            </template>
          </el-table-column>
          <el-table-column label="发票类型">
            <template slot-scope="scope">
              <span>{{
                scope.row.invoiceType == 0
                  ? "电子"
                  : scope.row.invoiceType == 1
                  ? "纸质"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              <span>{{
                scope.row.entityType == 0
                  ? "报名"
                  : scope.row.entityType == 1
                  ? "酒店"
                  : scope.row.entityType == 2
                  ? "门票"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="导出状态">
            <template slot-scope="scope">
              <span>{{
                scope.row.downloadStatus == 0
                  ? "未导出"
                  : scope.row.downloadStatus == 1
                  ? "已导出"
                  : scope.row.downloadStatus == 2
                  ? "已确认"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请时间">
            <template slot-scope="scope">
              <span>{{ scope.row.applyTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="confirm(scope.$index, scope.row)"
                v-show="scope.row.downloadStatus == 1"
                type="danger"
                >确认</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      total: 0,
      currentPage: +this.$route.params.currentPage,
      pageSize: +this.$route.params.pageSize,
      total: 0,
      teamName: "",
      tableData: [],
      isConfirm: true,
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleSizeChange(val) {
      this.$router.replace({
        name: "SportsDetail",
        params: {
          back: this.$route.params.back,
          id: this.$route.params.id,
          name: this.$route.params.name,
          menu: this.$route.params.menu,
          pageSize: val,
          currentPage: +this.$route.params.currentPage,
        },
      });
      this.getTableData();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.$router.replace({
        name: "SportsDetail",
        params: {
          back: this.$route.params.back,
          id: this.$route.params.id,
          name: this.$route.params.name,
          menu: this.$route.params.menu,
          pageSize: +this.$route.params.pageSize,
          currentPage: val,
        },
      });
      this.getTableData();
      console.log(`当前页: ${val}`);
    },
    //获取所有报名单位信息
    async getTableData() {
      try {
        const result = await this.$api.getInvoiceList(
          `search_EQ_competitionId=${this.id}&currentPage=${this.$route.params.currentPage}&pageSize=${this.$route.params.pageSize}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.total = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //导出报名单位信息表
    async handleExportUnit() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportInvoice(
          `search_EQ_competitionId=${this.id}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          this.getTableData();
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    confirm(index, row) {
      console.log(row);
      this.$confirm("是否确认已开发票?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.confirmInvoice(`id=${row.id}`);
          if (result.data.status_code == 200) {
            this.getTableData();
          }
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
};
</script>

<style lang="less">
.Invoice {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          font-size: 20px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
