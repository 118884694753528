<template>
  <div class="Registration">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">

          <!-- <el-button style="height:40px" type="primary" plain disabled
            >导出开票申请</el-button
          > -->
          <!-- <el-button style="height:40px" type="primary" plain
            >导出展演舞</el-button
          > -->
          <span style="height: 40px; line-height: 40px; margin-left: 5px"
            >代表队：</span
          >
          <el-input
            style="margin-right: 10px; width: 100px"
            v-model="teamName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 5px"
            >选手姓名：</span
          >
          <el-input
            style="margin-right: 10px; width: 100px"
            v-model="playerName"
          ></el-input>

          <span style="height: 40px; line-height: 40px; margin-left: 5px"
            >组别名称：</span
          >
          <el-input
            style="margin-right: 5px; width: 100px"
            v-model="groupCodeName"
          ></el-input>
          <span style="height: 40px; line-height: 40px">组别代码：</span>
          <el-input
            style="margin-right: 5px; width: 100px"
            v-model="groupCode"
          ></el-input>
          <span style="height: 40px; line-height: 40px">支付时间：</span>
          <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="payStartTime"
                  style="width: 150px"
                  value-format="yyyy-MM-dd">
          </el-date-picker>～
          <el-date-picker
                  placeholder="选择日期"
                  v-model="payEndTime"
                  value-format="yyyy-MM-dd"
                  style="width: 150px">
          </el-date-picker>
          <el-button
                  style="height: 40px; margin-left: 10px"
                  type="primary"
                  plain
                  @click="handleSearch"
          >搜索</el-button>
        </div>
        <div class="btn2">
          <el-button
                  style="height: 40px; margin-right: 5px"
                  type="primary"
                  plain
                  @click="downloadTemplate"
          >下载模板</el-button
          >
          <el-upload
                  :action="urls.uploadApplyExcel"
                  name="file"
                  :headers="{
              Authorization: stores.state.token,
            }"
                  :data="{ competitionId: this.competition }"
                  :multiple="false"
                  :show-file-list="false"
                  :on-success="uploadZfb"
                  :on-error="uploadZfb"
                  list-type="文件类型列表"
          >
            <el-button
                    style="height: 40px; margin-right: 5px"
                    type="primary"
                    plain
            >上传报名表格</el-button
            >
          </el-upload>
          <el-button
                  style="height: 40px"
                  type="primary"
                  plain
                  @click="handleExportApply"
          >导出报名表</el-button
          >
          <!-- <el-button style="height:40px" type="primary" plain
            >导出竖式报名表</el-button
          > -->
          <el-button
                  style="height: 40px"
                  type="primary"
                  plain
                  @click="handleExportPayInfo"
          >导出支付信息</el-button
          >
          <el-button
                  style="height: 40px"
                  type="primary"
                  plain
                  @click="handleExportAllPlayers"
          >导出选手信息</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="组别代码">
            <template slot-scope="scope">
              <span>{{ scope.row.groupCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称">
            <template slot-scope="scope">
              <span>{{ scope.row.groupName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选手姓名">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.playerNames" :key="index"
                >{{ item }}&nbsp;</span
              >
            </template>
          </el-table-column>
          <el-table-column label="赛制">
            <template slot-scope="scope">
              <span>{{
                scope.row.groupRaceType == 0
                  ? "名次赛"
                  : scope.row.groupRaceType == 1
                  ? "等级赛"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参赛服务费">
            <template slot-scope="scope">
              <span>{{ scope.row.price / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="支付状态">
            <template slot-scope="scope">
              <span>{{ payStatus[scope.row.payStatus].value }}</span>
            </template>
          </el-table-column>
          <el-table-column label="支付时间">
            <template slot-scope="scope">
              <span>{{ scope.row.payTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="有无折扣">
            <template slot-scope="scope">
              <span>{{
                scope.row.isDiscount == 0
                  ? "无"
                  : scope.row.isDiscount == 1
                  ? "有"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="负责人">
            <template slot-scope="scope">
              <span>{{ scope.row.contactName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="负责人电话">
            <template slot-scope="scope">
              <span>{{ scope.row.contactPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="地址">
            <template slot-scope="scope">
              <span>{{ scope.row.address }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="payInfo">
          <span class="pay">合计金额:{{ totalPrice / 100 }}</span>
          <span class="pay">折扣金额:{{ discountPrice / 100 }}</span>
          <span class="pay">实付金额:{{ payPrice / 100 }}</span>
        </div>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import group from "../api/group";
import store from "../store";
export default {
  data() {
    return {
      urls: group.URLs,
      stores: store,
      id: this.$route.params.id,
      competition: this.$route.params.id,
      total: 10.0,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      groupCodeName: "",
      groupCode: "",
      playerName: "",
      teamName: "",
      payStartTime: "",
      payEndTime: "",
      tableData: [],
      unitList: [],
      discountPrice: 0,
      payPrice: 0,
      totalPrice: 0,
      payStatus: [
        {
          status: 0,
          value: "",
        },
        {
          status: 1,
          value: "待支付",
        },
        {
          status: 2,
          value: "支付成功",
        },
        {
          status: 3,
          value: "已退赛",
        },
        {
          status: 4,
          value: "退款中",
        },
        {
          status: 5,
          value: "已退款",
        },
        {
          status: 6,
          value: "退款失败",
        },
      ],
    };
  },
  created() {
    this.getTableData();
    // this.getUnitDrop()
  },
  methods: {
    downloadTemplate() {
      window.location.href =
        "http://asset.idance5.com/competition/template/upload_apply_template.xlsx";
    },
    //获取单位下拉框信息
    async getUnitDrop() {
      try {
        const result = await this.$api.unitDrop();
        this.unitList = result;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    uploadZfb(callBackData) {
      const { status_code, msg } = callBackData;
      console.log(callBackData);
      if (status_code === "200") {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.getTabeleData();
      } else {
        this.$message({
          type: "warning",
          message: msg,
        });
      }
    },
    //搜索赛事报名信息
    async handleSearch() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.findApply(
          `search_LIKE_playerName=${this.playerName}&search_EQ_competitionId=${this.id}&search_LIKE_groupName=${this.groupCodeName}&search_LIKE_groupCode=${this.groupCode}&currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_LIKE_teamName=${
            this.teamName
          }&search_LT_payTime=${
            this.payEndTime?this.payEndTime:''
          }&search_GT_payTime=${
            this.payStartTime?this.payStartTime:''
          }`
        );
        this.tableData = result.pageInfo.pageResults;
        this.tableData.forEach((item) => {
          item.playerNames = JSON.parse(item.playerNames);
        });
        this.totalCount = result.pageInfo.totalCount;
        this.discountPrice = result.discountPrice;
        this.payPrice = result.payPrice;
        this.totalPrice = result.totalPrice;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //当前显示条数改变
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    //当前页跳转
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    //获取所有赛事报名信息
    async getTableData() {
      try {
        const result = await this.$api.findApply(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}&playerName=${this.playerName}&groupCodeName=${
            this.groupCodeName
          }&search_LT_payTime=${
            this.payEndTime?this.payEndTime:''
          }&search_GT_payTime=${
            this.payStartTime?this.payStartTime:''
          }`
        );
        console.log(result);
        this.tableData = result.pageInfo.pageResults;
        this.tableData.forEach((item) => {
          item.playerNames = JSON.parse(item.playerNames);
        });
        this.totalCount = result.pageInfo.totalCount;
        this.discountPrice = result.discountPrice;
        this.payPrice = result.payPrice;
        this.totalPrice = result.totalPrice;
      } catch (error) {
        console.log(error);
      }
    },
    //导出赛事报名信息
    async handleExportApply() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportApplyInfo(
          `search_EQ_competitionId=${this.id}`
        );
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //导出赛事支付信息
    async handleExportPayInfo() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportApplyPayInfo(
          `search_EQ_competitionId=${this.id}`
        );
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        console.log(result);
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //导出全部选手信息
    async handleExportAllPlayers() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportAllPlayers(
          `competitionId=${this.id}`
        );
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        console.log(result);
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style lang="less">
.Registration {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          display: block;
          font-style: italic;
          font-weight: bold;
          font-size: 24px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
