<template>
  <div class="InformationPush">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <el-button style="height: 40px" type="primary" plain @click="add"
            >新增</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table :data="tableData" style="width: 100%" height="540">
          <el-table-column label="发送人">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="推送时间">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="省份">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="城市">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="邮件/短信内容">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="代表队详情">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog title="新增" :visible.sync="dialogFormVisible" center>
      <el-form :model="form">
        <el-form-item label="省" :label-width="formLabelWidth">
          <el-select v-model="form.region" placeholder="请选择省份">
            <el-option label="江苏" value="江苏"></el-option>
            <el-option label="浙江" value="浙江"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="城市(不选城市代表省份下所有城市)"
          :label-width="formLabelWidth"
        >
          <el-select v-model="form.region" placeholder="请选择市">
            <el-option label="杭州" value="杭州"></el-option>
            <el-option label="宁波" value="宁波"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formLabelWidth: "300px",
      total: 10.0,
      currentPage4: 4,
      options: [
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        },
      ],
      playerName: "",
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      value: "请选择",
      value2: true,
      tableData: [
        {
          Cdate: "2016-05-02",
          RDdate: "2016-05-03",
          PDdate: "2016-05-04",
          num: 1,
          name: "赛事测试",
          flag: false,
          radio: "",
        },
        {
          Cdate: "2016-05-02",
          RDdate: "2016-05-03",
          PDdate: "2016-05-04",
          num: 2,
          name: "赛事测试2",
          flag: false,
          radio: "",
        },
      ],
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    add() {
      this.dialogFormVisible = true;
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less">
.InformationPush {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          font-size: 20px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
