<template>
  <div class="GroupDetail">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <el-button
            style="height: 40px; margin-right: 10px"
            type="primary"
            plain
            @click="handleAdd"
            >新增组别</el-button
          >
          <el-upload
            :action="urls.importGroup"
            name="file"
            :headers="{
              Authorization: stores.state.token,
            }"
            :data="{ competitionId: this.competition }"
            :multiple="false"
            :show-file-list="false"
            :on-success="uploadFileSuccess"
            :on-error="uploadFileSuccess"
            list-type="文件类型列表"
          >
            <el-button
              style="height: 40px; margin-right: 10px"
              type="primary"
              plain
              >上传组别</el-button
            >
          </el-upload>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleExport"
            >导出组别</el-button
          >
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="exportTable"
            >模板下载</el-button
          >
          <span style="height: 40px; line-height: 40px; margin-left: 20px"
            >组别名称：</span
          >
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="groupCodeName"
          ></el-input>
          <span style="height: 40px; line-height: 40px">组别代码：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="coding"
          ></el-input>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="searchSchedule"
            >搜索</el-button
          >
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="deleteAll"
            >清空组别</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="组别代码" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.groupCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="舞种" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.danceType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别类型" width="100">
            <template slot-scope="scope">
              <span>{{
                scope.row.groupType != null
                  ? groupType[scope.row.groupType].label
                  : scope.row.groupType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="赛制">
            <template slot-scope="scope">
              <span>{{
                raceType[scope.row.raceType]
                  ? raceType[scope.row.raceType].label
                  : scope.row.raceType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参赛服务费" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.price / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别详情">
            <template slot-scope="scope">
              <span>{{ scope.row.info }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收费方式">
            <template slot-scope="scope">
              <span>{{
                chargeWay[scope.row.chargeWay]
                  ? chargeWay[scope.row.chargeWay].label
                  : scope.row.chargeWay
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="舞种分类">
            <template slot-scope="scope">
              <span>{{ scope.row.danceTypeGroup }}</span>
            </template>
          </el-table-column>
          <el-table-column label="专业情况">
            <template slot-scope="scope">
              <span>{{
                majorType[scope.row.majorType]
                  ? majorType[scope.row.majorType].label
                  : scope.row.majorType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="专业组别">
            <template slot-scope="scope">
              <span>{{
                professionalGroupType[scope.row.professionalGroupType]
                  ? professionalGroupType[scope.row.professionalGroupType].label
                  : scope.row.professionalGroupType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参与折扣">
            <template slot-scope="scope">
              <span>{{
                whether[scope.row.isJoinDiscount]
                  ? whether[scope.row.isJoinDiscount].label
                  : scope.row.isJoinDiscount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="展演舞">
            <template slot-scope="scope">
              <span>{{
                whether[scope.row.isPerformanceDance]
                  ? whether[scope.row.isPerformanceDance].label
                  : scope.row.isPerformanceDance
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="团体舞">
            <template slot-scope="scope">
              <span>{{
                whether[scope.row.isGroupDance]
                  ? whether[scope.row.isGroupDance].label
                  : scope.row.isGroupDance
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="注册选手">
            <template slot-scope="scope">
              <span>{{
                whether[scope.row.isCbdf]
                  ? whether[scope.row.isCbdf].label
                  : scope.row.isCbdf
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="注册类型">
            <template slot-scope="scope">
              <span>{{
                registerTypes[scope.row.registerType]
                  ? registerTypes[scope.row.registerType].label
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="年龄段标识" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.ageFlag }}</span>
            </template>
          </el-table-column>
          <el-table-column label="年龄范围">
            <template slot-scope="scope">
              <span>{{ scope.row.minAge }}</span> ~<span>{{
                scope.row.maxAge
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="成人组">
            <template slot-scope="scope">
              <span>{{
                whether[scope.row.isAudit]
                  ? whether[scope.row.isAudit].label
                  : scope.row.isAudit
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="代表队限报数" width="110">
            <template slot-scope="scope">
              <span>{{ scope.row.teamLimit }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别限报数" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.groupLimit }}</span>
            </template>
          </el-table-column>
          <el-table-column label="冲突组别">
            <template slot-scope="scope">
              <span>{{ scope.row.rejectGroupCodes }}</span>
            </template>
          </el-table-column>
          <el-table-column label="报名总数">
            <template slot-scope="scope">
              <span>{{ scope.row.groupCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      title="编辑组别"
      :visible.sync="dialogFormEditVisible"
      width="90%"
      class="group"
      :close-on-click-modal="false"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        label-width="200px"
        :label-position="labelPosition"
        :rules="rules"
      >
        <div style="display: flex; flex-direction: column; margin-bottom: 30px">
          <div style="display: flex">
            <el-form-item label="组别名称:" prop="name">
              <el-input v-model="editForm.name" style="width: 217px"></el-input>
            </el-form-item>
            <el-form-item label="组别详情:">
              <el-input v-model="editForm.info" style="width: 217px"></el-input>
            </el-form-item>
            <el-form-item label="组别代码:" prop="groupCode">
              <el-input
                v-model="editForm.groupCode"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="舞种分类:" prop="danceTypeGroup">
              <el-select v-model="editForm.danceTypeGroup">
                <el-option label="未分类" value="未分类"></el-option>
                <el-option label="摩登" value="摩登"></el-option>
                <el-option label="拉丁" value="拉丁"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="舞种:" prop="danceType">
              <el-select v-model="editForm.danceType" multiple>
                <el-option label="C" value="C"></el-option>
                <el-option label="R" value="R"></el-option>
                <el-option label="S" value="S"></el-option>
                <el-option label="P" value="P"></el-option>
                <el-option label="J" value="J"></el-option>
                <el-option label="W" value="W"></el-option>
                <el-option label="T" value="T"></el-option>
                <el-option label="VW" value="VW"></el-option>
                <el-option label="F" value="F"></el-option>
                <el-option label="Q" value="Q"></el-option>
                <el-option label="6J" value="6J"></el-option>
                <el-option label="6Y" value="6Y"></el-option>
                <el-option label="6T" value="6T"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="组别类型:" prop="groupType">
              <el-select v-model="editForm.groupType">
                <el-option
                  v-for="item in groupType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="赛制:" prop="raceType">
              <el-select v-model="editForm.raceType">
                <el-option
                  v-for="item in raceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否为展演舞:" prop="isPerformanceDance">
              <el-select v-model="editForm.isPerformanceDance">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="是否为团体舞:" prop="isGroupDance">
              <el-select v-model="editForm.isGroupDance">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业情况:" prop="majorType">
              <el-select v-model="editForm.majorType">
                <el-option
                  v-for="item in majorType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业组别:" prop="professionalGroupType">
              <el-select v-model="editForm.professionalGroupType">
                <el-option
                  v-for="item in professionalGroupType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否为成人组:" prop="isAudit">
              <el-select v-model="editForm.isAudit">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <div style="display: flex; margin-bottom: 20px">
          <el-form-item label="每个代表队限报数(0不限制):" prop="teamLimit">
            <el-input
              v-model="editForm.teamLimit"
              style="width: 217px"
            ></el-input>
          </el-form-item>
          <el-form-item label="冲突组别代码:">
            <el-input
              v-model="editForm.rejectGroupCodes"
              style="width: 217px"
            ></el-input>
            <div>(用英文逗号隔开)</div>
          </el-form-item>
          <el-form-item label="该组别限报数(0为不限制):" prop="groupLimit">
            <el-input
              v-model="editForm.groupLimit"
              style="width: 217px"
            ></el-input>
          </el-form-item>
          <el-form-item label="参与折扣:" prop="isJoinDiscount">
            <el-select v-model="editForm.isJoinDiscount">
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div style="display: flex; margin-bottom: 30px">
          <el-form-item label="参赛服务费方式:" prop="chargeWay">
            <el-select v-model="editForm.chargeWay">
              <el-option
                v-for="item in chargeWay"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="参赛服务费:" prop="price">
            <el-input v-model="editForm.price" style="width: 217px"></el-input>
          </el-form-item>
        </div>

        <div style="display: flex; flex-direction: column; margin-bottom: 30px">
          <div style="display: flex">
            <el-form-item label="该师生ABC组要报单项数量:" prop="tsGroupCount">
              <el-input
                v-model="editForm.tsGroupCount"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="限年度组别总积分前几名(0为不限制):"
              prop="pointsReq"
            >
              <el-input
                v-model="editForm.pointsReq"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否为注册选手报名:" prop="isCbdf">
              <el-select v-model="editForm.isCbdf" @change="isShowEditRegister">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="注册类型:"
              prop="registerType"
              v-if="editForm.showRegister"
            >
              <el-select v-model="editForm.registerType">
                <el-option
                  v-for="item in registerTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="资格赛晋级到A组代码:">
              <el-input
                v-model="editForm.nextaGroupCode"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="资格赛晋级到B组代码:">
              <el-input
                v-model="editForm.nextbGroupCode"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否为精英组认证选手:" prop="isElite">
              <el-select v-model="editForm.isElite" @change="isShowEditGrade">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="精英组认证等级:"
              prop="eliteAuthGrade"
              v-if="editForm.showGrade"
            >
              <el-select v-model="editForm.eliteAuthGrade">
                <el-option label="8岁女单" value="8岁女单"></el-option>
                <el-option label="10岁女单" value="10岁女单"></el-option>
                <el-option label="12岁女单" value="12岁女单"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item
            label="年龄段标识(用于精英组同一年龄段标识，0代表无标识):"
            prop="ageFlag"
            style="width: 400px"
          >
            <el-input
              v-model="editForm.ageFlag"
              style="width: 217px"
            ></el-input>
          </el-form-item>
        </div>
        <div style="display: flex; flex-direction: column">
          <div style="display: flex">
            <el-form-item label="最小年龄:" prop="minAge">
              <el-input
                v-model="editForm.minAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="最大年龄:" prop="maxAge">
              <el-input
                v-model="editForm.maxAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="最少必须满足年龄范围人数:" prop="playerAge">
              <el-input
                v-model="editForm.playerAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="最多可大于年龄的人数:" prop="playerGraterAge">
              <el-input
                v-model="editForm.playerGraterAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="最多可小于年龄的人数:" prop="playerLessAge">
              <el-input
                v-model="editForm.playerLessAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="相加年龄大于等于(0为不限制):"
              prop="ageTotalMaxThan"
            >
              <el-input
                v-model="editForm.ageTotalMaxThan"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="相加年龄小于等于(0为不限制):"
              prop="ageTotalLessThan"
            >
              <el-input
                v-model="editForm.ageTotalLessThan"
                style="width: 217px"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <div style="display: flex">
          <el-form-item label="是否显示:" prop="isShow">
            <el-select v-model="editForm.isShow">
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushEditGroup">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="添加组别"
      :visible.sync="dialogFormAddVisible"
      width="90%"
      class="group"
      @close="dialogClose"
      :close-on-click-modal="false"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="200px"
        :label-position="labelPosition"
        :rules="rules"
      >
        <div style="display: flex; flex-direction: column; margin-bottom: 30px">
          <div style="display: flex">
            <el-form-item label="组别名称:" prop="name">
              <el-input v-model="addForm.name" style="width: 217px"></el-input>
            </el-form-item>
            <el-form-item label="组别详情:">
              <el-input v-model="addForm.info" style="width: 217px"></el-input>
            </el-form-item>
            <el-form-item label="组别代码:" prop="groupCode">
              <el-input
                v-model="addForm.groupCode"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="舞种分类:" prop="danceTypeGroup">
              <el-select v-model="addForm.danceTypeGroup">
                <el-option label="未分类" value="未分类"></el-option>
                <el-option label="摩登" value="摩登"></el-option>
                <el-option label="拉丁" value="拉丁"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="舞种:" prop="danceType">
              <el-select v-model="addForm.danceType" multiple>
                <el-option label="C" value="C"></el-option>
                <el-option label="R" value="R"></el-option>
                <el-option label="S" value="S"></el-option>
                <el-option label="P" value="P"></el-option>
                <el-option label="J" value="J"></el-option>
                <el-option label="W" value="W"></el-option>
                <el-option label="T" value="T"></el-option>
                <el-option label="VW" value="VW"></el-option>
                <el-option label="F" value="F"></el-option>
                <el-option label="Q" value="Q"></el-option>
                <el-option label="6J" value="6J"></el-option>
                <el-option label="6Y" value="6Y"></el-option>
                <el-option label="6T" value="6T"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="组别类型:" prop="groupType">
              <el-select v-model="addForm.groupType">
                <el-option
                  v-for="item in groupType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="赛制:" prop="raceType">
              <el-select v-model="addForm.raceType">
                <el-option
                  v-for="item in raceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否为展演舞:" prop="isPerformanceDance">
              <el-select v-model="addForm.isPerformanceDance">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="是否为团体舞:" prop="isGroupDance">
              <el-select v-model="addForm.isGroupDance">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业情况:" prop="majorType">
              <el-select v-model="addForm.majorType">
                <el-option
                  v-for="item in majorType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业组别:" prop="professionalGroupType">
              <el-select v-model="addForm.professionalGroupType">
                <el-option
                  v-for="item in professionalGroupType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否为成人组:" prop="isAudit">
              <el-select v-model="addForm.isAudit">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <div style="display: flex; margin-bottom: 20px">
          <el-form-item label="每个代表队限报数(0不限制):" prop="teamLimit">
            <el-input
              v-model="addForm.teamLimit"
              style="width: 217px"
            ></el-input>
          </el-form-item>
          <el-form-item label="冲突组别代码:">
            <el-input
              v-model="addForm.rejectGroupCodes"
              style="width: 217px"
            ></el-input>
            <div>(用英文逗号隔开)</div>
          </el-form-item>
          <el-form-item label="该组别限报数(0为不限制):" prop="groupLimit">
            <el-input
              v-model="addForm.groupLimit"
              style="width: 217px"
            ></el-input>
          </el-form-item>
          <el-form-item label="参与折扣:" prop="isJoinDiscount">
            <el-select v-model="addForm.isJoinDiscount">
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div style="display: flex; margin-bottom: 30px">
          <el-form-item label="参赛服务费方式:" prop="chargeWay">
            <el-select v-model="addForm.chargeWay">
              <el-option
                v-for="item in chargeWay"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="参赛服务费:" prop="price">
            <el-input v-model="addForm.price" style="width: 217px"></el-input>
          </el-form-item>
        </div>

        <div style="display: flex; flex-direction: column; margin-bottom: 30px">
          <div style="display: flex">
            <el-form-item label="该师生ABC组要报单项数量:" prop="tsGroupCount">
              <el-input
                v-model="addForm.tsGroupCount"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="限年度组别总积分前几名(0为不限制):"
              prop="pointsReq"
            >
              <el-input
                v-model="addForm.pointsReq"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否为注册选手报名:" prop="isCbdf">
              <el-select v-model="addForm.isCbdf" @change="isShowRegister">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="注册类型:"
              prop="registerType"
              v-if="addForm.showRegister"
            >
              <el-select v-model="addForm.registerType">
                <el-option
                  v-for="item in registerTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="资格赛晋级到A组代码:">
              <el-input
                v-model="addForm.nextaGroupCode"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="资格赛晋级到B组代码:">
              <el-input
                v-model="addForm.nextbGroupCode"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否为精英组认证选手:" prop="isElite">
              <el-select v-model="addForm.isElite" @change="isShowGrade">
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="精英组认证等级:"
              prop="eliteAuthGrade"
              v-if="addForm.showGrade"
            >
              <el-select v-model="addForm.eliteAuthGrade">
                <el-option label="8岁女单" value="8岁女单"></el-option>
                <el-option label="10岁女单" value="10岁女单"></el-option>
                <el-option label="12岁女单" value="12岁女单"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item
            label="年龄段标识(用于精英组同一年龄段标识，0代表无标识):"
            prop="ageFlag"
            style="width: 400px"
          >
            <el-input v-model="addForm.ageFlag" style="width: 217px"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex; flex-direction: column">
          <div style="display: flex">
            <el-form-item label="最小年龄:" prop="minAge">
              <el-input
                v-model="addForm.minAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="最大年龄:" prop="maxAge">
              <el-input
                v-model="addForm.maxAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="最少必须满足年龄范围人数:" prop="playerAge">
              <el-input
                v-model="addForm.playerAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="最多可大于年龄的人数:" prop="playerGraterAge">
              <el-input
                v-model="addForm.playerGraterAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="最多可小于年龄的人数:" prop="playerLessAge">
              <el-input
                v-model="addForm.playerLessAge"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="相加年龄大于等于(0为不限制):"
              prop="ageTotalMaxThan"
            >
              <el-input
                v-model="addForm.ageTotalMaxThan"
                style="width: 217px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="相加年龄小于等于(0为不限制):"
              prop="ageTotalLessThan"
            >
              <el-input
                v-model="addForm.ageTotalLessThan"
                style="width: 217px"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <div style="display: flex">
          <el-form-item label="是否显示:" prop="isShow">
            <el-select v-model="addForm.isShow">
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushGroup">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import group from "../api/group";
import store from "../store";
export default {
  data() {
    return {
      totalCount: 0,
      urls: group.URLs,
      stores: store,
      isUp: false,
      competition: this.$route.params.id,
      id: this.$route.params.id,
      groupCode: "",
      name: "",
      groupCodeName: "",
      coding: "",
      fileList: [],
      labelPosition: "right",
      options: [
        {
          value: "选项1",
          label: "所有",
        },
        {
          value: "选项2",
          label: "可见",
        },
        {
          value: "选项3",
          label: "不可见",
        },
      ],
      value: "所有",
      value2: true,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogFormEditVisible: false,
      dialogFormAddVisible: false,
      codeName: "",
      editForm: {},
      addForm: {
        showRegister: false,
        showGrade: false,
      },
      rules: {
        name: [{ required: true, message: "请输入组别名称", trigger: "blur" }],
        info: [{ required: true, message: "请输入组别详情", trigger: "blur" }],
        groupCode: [
          { required: true, message: "请输入组别代码", trigger: "blur" },
        ],
        rejectGroupCodes: [
          { required: true, message: "请输入冲突组别代码", trigger: "blur" },
        ],
        groupLimit: [
          { required: true, message: "请输入组别限报数", trigger: "blur" },
        ],
        tsGroupCount: [
          { required: true, message: "请输入单项数量", trigger: "blur" },
        ],
        danceTypeGroup: [
          { required: true, message: "请选择舞种分类", trigger: "blur" },
        ],
        danceType: [{ required: true, message: "请选择舞种", trigger: "blur" }],
        groupType: [
          { required: true, message: "请选择组别类型", trigger: "blur" },
        ],
        raceType: [{ required: true, message: "请选择赛制", trigger: "blur" }],
        chargeWay: [
          { required: true, message: "请选择参赛服务费方式", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入参赛服务费", trigger: "blur" },
        ],
        isJoinDiscount: [
          { required: true, message: "请选择是否参与折扣", trigger: "blur" },
        ],
        isAudit: [
          { required: true, message: "请选择是否是成人组", trigger: "blur" },
        ],
        majorType: [
          { required: true, message: "请选择专业情况", trigger: "blur" },
        ],
        professionalGroupType: [
          { required: true, message: "请选择专业组别", trigger: "blur" },
        ],
        teamLimit: [
          { required: true, message: "请输入限报数", trigger: "blur" },
        ],
        pointsReq: [
          {
            required: true,
            message: "请输入限年度总积分前几名",
            trigger: "blur",
          },
        ],
        isPerformanceDance: [
          { required: true, message: "请选择是否为展演舞", trigger: "blur" },
        ],
        isGroupDance: [
          { required: true, message: "请选择是否为团体舞", trigger: "blur" },
        ],
        isCbdf: [
          {
            required: true,
            message: "请选择是否为注册选手报名",
            trigger: "blur",
          },
        ],
        registerType: [
          {
            required: true,
            message: "请选择注册类型",
            trigger: "blur",
          },
        ],
        isElite: [
          {
            required: true,
            message: "请选择是否为精英组选手",
            trigger: "blur",
          },
        ],
        eliteAuthGrade: [
          {
            required: true,
            message: "请选择精英组认证等级",
            trigger: "blur",
          },
        ],
        ageFlag: [
          { required: true, message: "请输入年龄段标识", trigger: "blur" },
        ],
        minAge: [
          { required: true, message: "请输入最小年龄", trigger: "blur" },
        ],
        maxAge: [
          { required: true, message: "请输入最大年龄", trigger: "blur" },
        ],
        playerAge: [
          {
            required: true,
            message: "请输入最少必须满足年龄范围人数",
            trigger: "blur",
          },
        ],
        playerGraterAge: [
          {
            required: true,
            message: "请输入最多可大于年龄的人数",
            trigger: "blur",
          },
        ],
        playerLessAge: [
          {
            required: true,
            message: "请输入最多可小于年龄的人数",
            trigger: "blur",
          },
        ],
        ageTotalLessThan: [
          {
            required: true,
            message: "请输入相加年龄小于等于",
            trigger: "blur",
          },
        ],
        ageTotalMaxThan: [
          {
            required: true,
            message: "请输入相加年龄大于等于",
            trigger: "blur",
          },
        ],
        isShow: [
          { required: true, message: "请选择是否显示", trigger: "blur" },
        ],
        nextaGroupCode: [
          {
            required: true,
            message: "请输入资格赛晋级到A组代码",
            trigger: "blur",
          },
        ],
        nextbGroupCode: [
          {
            required: true,
            message: "请输入资格赛晋级到B组代码",
            trigger: "blur",
          },
        ],
      },
      groupType: [
        { label: "单人(男女不限)", value: 0 },
        { label: "男子单人", value: 1 },
        { label: "女子单人", value: 2 },
        { label: "男女双人", value: 3 },
        { label: "女子双人", value: 4 },
        { label: "六人组(男女不限)", value: 5 },
        { label: "女子六人组", value: 6 },
        { label: "男子六人组", value: 7 },
        { label: "其他", value: 8 },
      ],
      raceType: [
        { label: "名次赛", value: 0 },
        { label: "等级赛", value: 1 },
      ],
      whether: [
        { label: "否", value: 0 },
        { label: "是", value: 1 },
      ],
      chargeWay: [
        { label: "按组别", value: 0 },
        { label: "按人数", value: 1 },
      ],
      majorType: [
        { label: "业余", value: 0 },
        { label: "专业", value: 1 },
      ],
      professionalGroupType: [
        { label: "常规组", value: 0 },
        { label: "职业组", value: 1 },
        { label: "甲A组", value: 2 },
        { label: "青年组", value: 3 },
        { label: "精英组", value: 4 },
        { label: "专业院校组", value: 5 },
        { label: "国少组", value: 6 },
        { label: "职业新星组", value: 7 },
        { label: "职业教师组", value: 8 },
        { label: "甲B组", value: 9 },
      ],
      registerTypes: [
        {
          label: "二类(青年组、专业组(21岁以下各组别)、高等院校专业组、少年组)",
          value: 0,
        },
        {
          label: "一类(职业组、职业新星组、职业教师组、甲A组、甲B组)",
          value: 1,
        },
      ],
    };
  },
  created() {
    this.getTabeleData();
  },
  methods: {
    //新增组别时是否显示注册类型判断
    isShowRegister() {
      if (this.addForm.isCbdf == 1) {
        this.addForm.showRegister = true;
      } else if (this.addForm.isCbdf == 0) {
        this.addForm.showRegister = false;
      }
    },
    //编辑组别时是否显示注册类型判断
    isShowEditRegister() {
      if (this.editForm.isCbdf == 1) {
        this.editForm.showRegister = true;
        this.editForm.registerType = "";
      } else if (this.editForm.isCbdf == 0) {
        this.editForm.showRegister = false;
        this.editForm.registerType = 2;
      }
    },
    //新增组别时是否显示精英认证等级判断
    isShowGrade() {
      if (this.addForm.isElite == 1) {
        this.addForm.showGrade = true;
      } else if (this.addForm.isElite == 0) {
        this.addForm.showGrade = false;
      }
    },
    //编辑组别时是否显示精英认证等级判断
    isShowEditGrade() {
      if (this.editForm.isElite == 1) {
        this.editForm.showGrade = true;
        this.editForm.eliteAuthGrade = "";
      } else if (this.editForm.isElite == 0) {
        this.editForm.showGrade = false;
        this.editForm.eliteAuthGrade = "";
      }
    },
    //关闭新增组别弹框后进行数据清空操作
    dialogClose() {
      this.addForm = {};
    },
    //下载组别模板
    exportTable() {
      window.location.href =
        "http://asset.idance5.com/download/template/competition/%E4%B8%8A%E4%BC%A0%E7%BB%84%E5%88%AB%E8%A1%A8.xls";
    },
    //上传组别时的回调处理
    uploadFileSuccess(callBackData) {
      const { status_code, msg } = callBackData;
      console.log(callBackData);
      if (status_code === "200") {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.getTabeleData();
      } else if (status_code == "1002") {
        this.$message({
          type: "warning",
          message: "文件格式不正确",
        });
      }
    },
    //编辑组别前对编辑内容的处理
    handleEdit(index, row) {
      console.log(row);
      this.dialogFormEditVisible = true;
      this.editForm = JSON.parse(JSON.stringify(row));
      this.editForm.price /= 100;
      if (this.editForm.isCbdf == 1) {
        // this.editForm.isCbdf = '是'
        this.editForm.showRegister = true;
      } else if (this.editForm.isCbdf == 0) {
        // this.editForm.isCbdf = '否'
        this.editForm.showRegister = false;
      }
      if (this.editForm.isElite == 1) {
        // this.editForm.isElite = '是'
        this.editForm.showGrade = true;
      } else if (this.editForm.isElite == 0) {
        // this.editForm.isElite = '否'
        this.editForm.showGrade = false;
      }
      this.editForm.danceType = this.editForm.danceType.split(",");
      console.log(this.editForm);
    },
    //新增组别弹框按钮
    handleAdd() {
      this.dialogFormAddVisible = true;
    },
    //获取组别
    async getTabeleData() {
      try {
        const result = await this.$api.groupData(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_EQ_competitionId=${this.id}&search_LIKE_groupName=${this.groupCodeName}&search_LIKE_groupCode=${this.coding}`
        );
        this.tableData = result.pageResults;
        console.log(result);
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //搜索组别
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.groupData(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_EQ_competitionId=${this.id}&search_LIKE_groupName=${this.groupCodeName}&search_LIKE_groupCode=${this.coding}`
        );
        this.tableData = result.pageResults;
        console.log(result);
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //导出组别
    async handleExport(index, row) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportGroup(`competitionId=${this.id}`);
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //清空所有组别
    deleteAll() {
      this.$confirm("确定要清空所有组别信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.deleteAllGroup(
            `competitionId=${this.competition}`
          );
          if (result.data.status_code == "200") {
            if (this.tableData.length == 0) {
              this.$message({
                type: "warning",
                message: "无可清空的数据",
              });
            } else if (this.tableData.length > 0) {
              this.$message({
                type: "success",
                message: "成功清除所有组别",
              });
              this.getTabeleData();
            }
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      });
    },
    //删除单个组别
    handleDelete(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.deleteGroup(`id=${id}`);
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getTabeleData();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      });
    },
    //每页显示条数改变
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTabeleData();
    },
    //当前页跳转
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTabeleData();
    },
    //提交新增的组别
    pushGroup() {
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          this.dialogFormAddVisible = false;
          this.addForm.competitionId = this.id;
          this.addForm.price = this.addForm.price * 100;
          this.addForm.groupTypeName =
            this.groupType[this.addForm.groupType].label;
          this.addForm.danceType = this.addForm.danceType.join(",");
          console.log(this.addForm);
          try {
            const result = await this.$api.addGroup(this.addForm);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.getTabeleData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //提交编辑后的组别
    pushEditGroup() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.dialogFormEditVisible = false;
          this.editForm.competitionId = this.id;
          this.editForm.price *= 100;
          delete this.editForm.createTime;
          delete this.editForm.updateTime;
          // if (this.editForm.isCbdf == '是') {
          //   this.editForm.isCbdf = '1'
          // } else if (this.editForm.isCbdf == '否') {
          //   this.editForm.isCbdf = '0'
          // }
          // if (this.editForm.isElite == '是') {
          //   this.editForm.isElite = '1'
          // } else if (this.editForm.isElite == '否') {
          //   this.editForm.isElite = '0'
          // }
          this.editForm.groupTypeName =
            this.groupType[this.editForm.groupType].label;
          this.editForm.danceType = this.editForm.danceType.join(",");
          console.log(this.editForm);
          try {
            const result = await this.$api.editGroup(this.editForm);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.getTabeleData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
.GroupDetail {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .group {
    .el-dialog {
      min-height: 1136px;
      min-width: 1713px;
    }
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
  .national {
    width: 65px;
  }
  .select {
    .el-input {
      width: 93%;
    }
  }
}
</style>
