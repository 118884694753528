<template>
  <div class="SportsDetail">
    <el-page-header @back="goBack" :content="nav" title=""> </el-page-header>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="1">
        <Bar1 :id="id"></Bar1>
      </el-tab-pane>
      <el-tab-pane label="组别信息" name="2">
        <Bar2 v-if="tab.name == '2'"></Bar2>
      </el-tab-pane>
      <!-- <el-tab-pane label="报名" name="3">
        <Bar3 v-if="tab.name == '3'"></Bar3>
      </el-tab-pane> -->
      <el-tab-pane label="待支付" name="4">
        <Bar4 v-if="tab.name == '4'"></Bar4>
      </el-tab-pane>
      <el-tab-pane label="报名表" name="5">
        <Bar5 v-if="tab.name == '5'"></Bar5>
      </el-tab-pane>
      <el-tab-pane label="报名单位" name="6">
        <Bar6 v-if="tab.name == '6'"></Bar6>
      </el-tab-pane>
      <el-tab-pane label="个人报名" name="7">
        <Bar7 v-if="tab.name == '7'"></Bar7>
      </el-tab-pane>
      <el-tab-pane label="发票" name="8">
        <Bar8 v-if="tab.name == '8'"></Bar8>
      </el-tab-pane>
      <el-tab-pane label="总赛程" name="9">
        <Bar9 v-if="tab.name == '9'"></Bar9>
      </el-tab-pane>
      <el-tab-pane label="分组赛程" name="10">
        <Bar10 v-if="tab.name == '10'"></Bar10>
      </el-tab-pane>
      <el-tab-pane label="成绩单" name="11">
        <Bar11 v-if="tab.name == '11'"></Bar11>
      </el-tab-pane>
      <!-- <el-tab-pane label="总排名(积分)" name="12">定时任务补</el-tab-pane> -->
      <el-tab-pane label="导入证书" name="13">
        <Bar13 v-if="tab.name == '13'"></Bar13>
      </el-tab-pane>
      <!-- <el-tab-pane label="信息推送" name="14">
        <Bar14 v-if="tab.name == '14'"></Bar14>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import Bar1 from '../../components/baseDetail.vue'
import Bar2 from '../../components/groupdetail.vue'
import Bar3 from '../../components/signup.vue'
import Bar4 from '../../components/tobepaid.vue'
import Bar5 from '../../components/registrations.vue'
import Bar6 from '../../components/registrationunit.vue'
import Bar7 from '../../components/personalres.vue'
import Bar8 from '../../components/invoice.vue'
import Bar9 from '../../components/schedule.vue'
import Bar10 from '../../components/groupschedule.vue'
import Bar11 from '../../components/achievement.vue'
import Bar13 from '../../components/Importcertificate.vue'
import Bar14 from '../../components/Informationpush.vue'

export default {
  data() {
    return {
      nav: this.$route.params.back,
      activeName: '1',
      id: this.$route.params.id,
      tab: {},
      name: '',
      memu:this.$route.params.menu
    }
  },
  components: {
    Bar1,
    Bar2,
    Bar3,
    Bar4,
    Bar5,
    Bar6,
    Bar7,
    Bar8,
    Bar9,
    Bar10,
    Bar11,
    Bar13,
    Bar14,
  },
  mounted() {
    this.updateType()
  },
  methods: {
    updateType() {
      let type = this.$route.params.name
      //通过拿到的值不同，更改activeName的值
      if (type == '1') {
        this.tab.name = '1'
        this.activeName = '1'
      } else if (type == '2') {
        this.tab.name = '2'
        this.activeName = '2'
      } else if (type == '3') {
        this.tab.name = '3'
        this.activeName = '3'
      } else if (type == '4') {
        this.tab.name = '4'
        this.activeName = '4'
      } else if (type == '5') {
        this.tab.name = '5'
        this.activeName = '5'
      } else if (type == '6') {
        this.tab.name = '6'
        this.activeName = '6'
      } else if (type == '7') {
        this.tab.name = '7'
        this.activeName = '7'
      } else if (type == '8') {
        this.tab.name = '8'
        this.activeName = '8'
      } else if (type == '9') {
        this.tab.name = '9'
        this.activeName = '9'
      } else if (type == '10') {
        this.tab.name = '10'
        this.activeName = '10'
      } else if (type == '11') {
        this.tab.name = '11'
        this.activeName = '11'
      } else if (type == '12') {
        this.tab.name = '12'
        this.activeName = '12'
      } else if (type == '13') {
        this.tab.name = '13'
        this.activeName = '13'
      } else if (type == '14') {
        this.tab.name = '14'
        this.activeName = '14'
      }
    },
    goBack() {
      this.$router.push({name:'Home'})
    },
    handleClick(tab, event) {
      // console.log(tab, event)
      this.tab = tab
      if (tab.name == '1') {
        this.name = 1
      } else if (tab.name == '2') {
        this.name = 2
      } else if (tab.name == '3') {
        this.name = 3
      } else if (tab.name == '4') {
        this.name = 4
      } else if (tab.name == '5') {
        this.name = 5
      } else if (tab.name == '6') {
        this.name = 6
      } else if (tab.name == '7') {
        this.name = 7
      } else if (tab.name == '8') {
        this.name = 8
      } else if (tab.name == '9') {
        this.name = 9
      } else if (tab.name == '10') {
        this.name = 10
      } else if (tab.name == '11') {
        this.name = 11
      } else if (tab.name == '12') {
        this.name = 12
      } else if (tab.name == '13') {
        this.name = 13
      } else if (tab.name == '14') {
        this.name = 14
      }
      this.$router.push({
        name: 'SportsDetail',
        params: { back: this.nav, id: this.id, name: this.name,menu:this.memu,pageSize:10,currentPage:1 },
      })
    },
  },
};
</script>

<style lang="less" scoped>
.SportsDetail {
}
</style>
