<template>
  <div class="PersonalRes">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >选手姓名：</span
          >
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="playerName"
          ></el-input>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="searchSchedule"
            >搜索</el-button
          >
          <!-- <el-button style="height:40px" type="primary" plain disabled
            >统一发送报名邮件</el-button
          > -->
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="选手姓名">
            <template slot-scope="scope">
              <span>{{ scope.row ? scope.row.username : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别">
            <template slot-scope="scope">
              <span>{{
                scope.row.gender == 0 ? "男" : scope.row.gender == 1 ? "女" : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称">
            <template slot-scope="scope">
              <span>{{ scope.row ? scope.row.groupName : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出生日期">
            <template slot-scope="scope">
              <span>{{ scope.row ? scope.row.birthday : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="证件类型">
            <template slot-scope="scope">
              <span>{{ scope.row ? scope.row.certType : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="证件号码">
            <template slot-scope="scope">
              <span>{{ scope.row ? scope.row.certNo : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="邮箱">
            <template slot-scope="scope">
              <span>{{ scope.row ? scope.row.email : "" }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="单位">
            <template slot-scope="scope">
              <span>{{ scope.row ? scope.row.username : ''}}</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column label="操作" width="350">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)"
                >详情</el-button
              >
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >发送报名邮件</el-button
              >
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >发送赛程邮件</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      playerName: "",
      tableData: [],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    //个人报名详情
    handleDetail(index, row) {
      console.log(index, row);
    },
    //发送邮件
    handleDelete() {},
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    //获取所有个人报名信息
    async getTableData() {
      try {
        const result = await this.$api.findPersonal(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&competitionId=${this.id}&playerName=${this.playerName}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.total = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //查询所有个人报名信息
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.findPersonal(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&competitionId=${this.id}&playerName=${this.playerName}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.total = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.PersonalRes {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          font-size: 20px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
