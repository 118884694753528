<template>
  <div class="SignUp">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <el-form style="width: 250px">
            <el-form-item label="第一步:">
              选择选手,点击"查询可报组别"
            </el-form-item>
          </el-form>
          <el-select v-model="value" placeholder="请选择" class="select1">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="请选择" class="select1">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button style="height: 40px" type="primary" plain
            >查询可报组别</el-button
          >
          <el-button style="height: 40px" type="primary" plain
            >检查报名条件</el-button
          >
          <el-button style="height: 40px" type="primary" plain
            >添加非本单位选手</el-button
          >
          <el-form style="width: 250px">
            <el-form-item label="报名截止:">
              <p>{{ day }}天{{ hour }}时{{ min }}分{{ second }}秒</p>
            </el-form-item>
          </el-form>
          <el-button style="height: 40px" type="primary" plain
            >展演舞报名</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table :data="tableData" style="width: 100%" height="540">
          <el-table-column label="姓名">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别">
            <template slot-scope="scope">
              <span>{{ scope.row.Cdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出生日期">
            <template slot-scope="scope">
              <span>{{ scope.row.RDdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="年龄">
            <template slot-scope="scope">
              <span>{{ scope.row.PDdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="专业选手">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="btn2">
        <el-form style="width: 380px">
          <el-form-item label="第二步:">
            在以下表格中选择需报名的组别，点击“提交”按钮
          </el-form-item>
        </el-form>
      </div>

      <div class="main-card">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="选中 ">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.flag"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="组别代码">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称">
            <template slot-scope="scope">
              <span>{{ scope.row.Cdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="舞种">
            <template slot-scope="scope">
              <span>{{ scope.row.RDdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选手列表">
            <template slot-scope="scope">
              <span>{{ scope.row.PDdate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="赛制">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参赛服务费方式">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参赛服务费">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      options: [
        {
          value: "选项1",
          label: "所有",
        },
        {
          value: "选项2",
          label: "可见",
        },
        {
          value: "选项3",
          label: "不可见",
        },
      ],
      value: "所有",
      value2: true,
      tableData: [
        {
          Cdate: "2016-05-02",
          RDdate: "2016-05-03",
          PDdate: "2016-05-04",
          num: 1,
          name: "赛事测试",
          flag: false,
          radio: "",
        },
        {
          Cdate: "2016-05-02",
          RDdate: "2016-05-03",
          PDdate: "2016-05-04",
          num: 2,
          name: "赛事测试2",
          flag: false,
          radio: "",
        },
      ],
      input1: "",
      input2: "",
      dialogFormVisible: false,
      curStartTime: "2021-08-31 08:00:00",
      day: "0",
      hour: "00",
      min: "00",
      second: "00",
    };
  },
  created() {
    this.curStartTime = "2021-08-31";
    this.countTime();
  },
  methods: {
    // 倒计时
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(this.curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        this.second = s < 10 ? "0" + s : s;
      } else {
        this.day = 0;
        this.hour = "00";
        this.min = "00";
        this.second = "00";
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000);
      }
    },
  },
};
</script>

<style lang="less">
.SignUp {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
