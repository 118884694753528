<template>
  <div class="GroupSchedule">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >日期：</span
          >
          <el-col :span="3">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="runDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 200px"
            ></el-date-picker>
          </el-col>
          <span style="height: 40px; line-height: 40px; margin-left: 30px"
            >场序：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="mpName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >组别代码：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="groupCode"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >组别名称：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="likeGroupName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >代表队：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="likeTeamName"
          ></el-input>
        </div>
        <div class="btn2">
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >背号：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="playerNo"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >选手：</span
          >
          <el-input
            style="margin-right: 5px; width: 200px"
            v-model="likePlayerName"
          ></el-input>
          <el-button
            style="height: 40px; margin-right: 10px; margin-left: 20px"
            type="primary"
            plain
            @click="queryScheduleGroup"
            >查询</el-button
          >
          <el-upload
            :action="urls.importScheduleGroup"
            name="file"
            :headers="{
              Authorization: stores.state.token,
            }"
            :data="{ competitionId: this.competitionId }"
            :multiple="false"
            :show-file-list="false"
            :on-success="uploadFileSuccess"
            :on-error="uploadFileSuccess"
            list-type="文件类型列表"
          >
            <el-button
              style="height: 40px; margin-right: 10px"
              type="primary"
              plain
              >导入</el-button
            >
          </el-upload>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="exportTable"
            >模板下载</el-button
          >
          <el-button style="height: 40px" type="primary" plain @click="empty"
            >清空分组表</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="日期">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.runDate).format("YYYY-MM-DD") }}</span>
            </template>
          </el-table-column>
          <el-table-column label="场序">
            <template slot-scope="scope">
              <span>{{ scope.row.mpName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="区域">
            <template slot-scope="scope">
              <span>{{ scope.row.areaName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开始时间">
            <template slot-scope="scope">
              <span>{{ scope.row.runTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别代码">
            <template slot-scope="scope">
              <span>{{ scope.row.groupCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称">
            <template slot-scope="scope">
              <span>{{ scope.row.groupName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别类型">
            <template slot-scope="scope">
              <span>{{ scope.row.groupType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="赛制">
            <template slot-scope="scope">
              <span>{{ scope.row.raceType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="轮次">
            <template slot-scope="scope">
              <span>{{ scope.row.turnName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="舞种">
            <template slot-scope="scope">
              <span>{{ scope.row.danceType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="代表队编码">
            <template slot-scope="scope">
              <span>{{ scope.row.teamCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="代表队名称">
            <template slot-scope="scope">
              <span>{{ scope.row.teamName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="背号">
            <template slot-scope="scope">
              <span>{{ scope.row.playerNo }}</span>
            </template>
          </el-table-column>
          <el-table-column label="连场休息">
            <template slot-scope="scope">
              <span>{{ scope.row.continuousScene }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选手1">
            <template slot-scope="scope">
              <span>{{ scope.row.playerNames }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选手2">
            <template slot-scope="scope">
              <span>{{ scope.row.idCards }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import schedule from "../api/schedule";
import store from "../store";
export default {
  data() {
    return {
      urls: schedule.URLs,
      stores: store,
      total: 10.0,
      currentPage: 1,
      pageSize: 10,
      competitionId: "",
      runDate: "",
      mpName: "",
      groupCode: "",
      likeGroupName: "",
      likeTeamName: "",
      likePlayerName: "",
      playerNo: "",
      totalCount: 0,
      playerName: "",
      tableData: [],
    };
  },
  created() {
    this.queryScheduleGroup();
  },
  methods: {
    //清空分组赛程信息
    empty() {
      this.$confirm("是否清空所有分组赛程数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.clearScheduleGroup(
            `competitionId=${this.competitionId}`
          );
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "清除成功",
            });
            this.queryScheduleGroup();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    //下载分组赛程模板
    exportTable() {
      window.location.href =
        "http://asset.idance5.com/download/template/competition/%E5%88%86%E7%BB%84%E8%A1%A8.xls";
    },
    //导入分组赛程后的回调
    uploadFileSuccess(callBackData) {
      const { status_code, msg } = callBackData;
      console.log(callBackData);
      if (status_code === "200") {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.queryScheduleGroup();
      } else if (status_code == "1002") {
        this.$message({
          type: "warning",
          message: msg,
        });
      }
    },
    //获取所有分组赛程信息
    async queryScheduleGroup() {
      this.competitionId = this.$route.params.id;
      try {
        const result = await this.$api.queryScheduleGroup(
          `currentPage=${this.currentPage}&pageSize=${
            this.pageSize
          }&competitionId=${this.competitionId}&runDate=${
            this.runDate ? this.runDate : ""
          }&mpName=${this.mpName}&groupCode=${this.groupCode}&likeGroupName=${
            this.likeGroupName
          }&likeTeamName=${this.likeTeamName}&likePlayerName=${
            this.likePlayerName
          }&playerNo=${this.playerNo}`
        );
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
        console.log(this.tableData);
      } catch (error) {
        console.log(error);
      }
    },
    //查询所有分组赛程信息
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.competitionId = this.$route.params.id;
      try {
        const result = await this.$api.queryScheduleGroup(
          `currentPage=${this.currentPage}&pageSize=${
            this.pageSize
          }&competitionId=${this.competitionId}&runDate=${
            this.runDate ? this.runDate : ""
          }&mpName=${this.mpName}&groupCode=${this.groupCode}&likeGroupName=${
            this.likeGroupName
          }&likeTeamName=${this.likeTeamName}&likePlayerName=${
            this.likePlayerName
          }&playerNo=${this.playerNo}`
        );
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
        console.log(this.tableData);
      } catch (error) {
        console.log(error);
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryScheduleGroup();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryScheduleGroup();
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less">
.GroupSchedule {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
      .btn2 {
        display: flex;
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
      .payInfo {
        margin: 10px 0;
        .pay {
          font-size: 20px;
        }
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
