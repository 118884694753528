<template>
  <div class="ToBePaid">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn1">
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleExportUnPay"
            >导出待支付表</el-button
          >
          <!-- <el-button style="height:40px" type="primary" plain
            >导出竖式报名表</el-button
          > -->
          <!-- <el-button style="height:40px;margin-right:20px" type="primary" plain
            >导出展演舞</el-button
          > -->
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >代表队：</span
          >
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="teamName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >选手姓名：</span
          >
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="playerName"
          ></el-input>

          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >组别名称：</span
          >
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="groupCodeName"
          ></el-input>
          <span style="height: 40px; line-height: 40px; margin-left: 10px"
            >组别代码：</span
          >
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="groupCode"
          ></el-input>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="searchSchedule"
            >搜索</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="540"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="组别代码">
            <template slot-scope="scope">
              <span>{{ scope.row.groupCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别名称">
            <template slot-scope="scope">
              <span>{{ scope.row.groupName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选手姓名">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.playerNames" :key="index"
                >{{ item }}&nbsp;</span
              >
            </template>
          </el-table-column>
          <el-table-column label="赛制">
            <template slot-scope="scope">
              <span>{{
                scope.row.groupRaceType == 0
                  ? "名次赛"
                  : scope.row.groupRaceType == 1
                  ? "等级赛"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参赛服务费">
            <template slot-scope="scope">
              <span>{{ scope.row.price / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="支付状态">
            <template slot-scope="scope">
              <span>{{ payStatus[scope.row.payStatus].value }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="有无折扣">
            <template slot-scope="scope">
              <span>{{ scope.row.num }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="单位">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="负责人">
            <template slot-scope="scope">
              <span>{{ scope.row.contactName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="负责人电话">
            <template slot-scope="scope">
              <span>{{ scope.row.contactPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="地址">
            <template slot-scope="scope">
              <span>{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <span
          style="
            margin-top: 20px;
            display: block;
            font-style: italic;
            font-weight: bold;
            font-size: 24px;
          "
        >
          合计金额:{{ totalPrice ? totalPrice / 100 : 0 }}
        </span>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      total: 10.0,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      totalPrice: 0,
      playerName: "",
      groupCodeName: "",
      groupCode: "",
      teamName: "",
      tableData: [],
      unitList: [],
      payStatus: [
        {
          status: 0,
          value: "",
        },
        {
          status: 1,
          value: "待支付",
        },
        {
          status: 2,
          value: "支付成功",
        },
        {
          status: 3,
          value: "已退赛",
        },
        {
          status: 4,
          value: "退款中",
        },
        {
          status: 5,
          value: "已退款",
        },
        {
          status: 6,
          value: "退款失败",
        },
      ],
    };
  },
  created() {
    this.getTableData();
    // this.getUnitDrop()
  },
  methods: {
    //获取单位下拉框数据
    async getUnitDrop() {
      try {
        const result = await this.$api.unitDrop();
        this.unitList = result;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //获取待支付信息
    async getTableData() {
      try {
        const result = await this.$api.toBePay(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_LIKE_playerName=${this.playerName}&search_LIKE_groupName=${this.groupCodeName}&search_LIKE_groupCode=${this.groupCode}&search_LIKE_teamName=${this.teamName}`
        );
        console.log(result);
        // if(result.pageInfo)
        this.tableData = result.pageInfo.pageResults;
        this.tableData.forEach((item) => {
          item.playerNames = JSON.parse(item.playerNames);
        });
        this.totalCount = result.pageInfo.totalCount;
        this.totalPrice = result.totalPrice;
      } catch (error) {
        console.log(error);
      }
    },
    //获取待支付信息
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.toBePay(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_LIKE_playerName=${this.playerName}&search_LIKE_groupName=${this.groupCodeName}&search_LIKE_groupCode=${this.groupCode}&search_LIKE_teamName=${this.teamName}`
        );
        console.log(result);
        // if(result.pageInfo)
        this.tableData = result.pageInfo.pageResults;
        this.tableData.forEach((item) => {
          item.playerNames = JSON.parse(item.playerNames);
        });
        this.totalCount = result.pageInfo.totalCount;
        this.totalPrice = result.totalPrice;
      } catch (error) {
        console.log(error);
      }
    },
    //删除待支付信息
    handleDelete(index, row) {
      this.$confirm("是否删除该数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.deleteToBePay(`applyId=${row.id}`);
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getTableData();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }

          console.log(result);
        } catch (error) {
          console.log(error);
        }
      });

      console.log(index, row);
    },
    //每页显示条数更改
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    //当前页跳转
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    //导出待支付表
    async handleExportUnPay() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportUnPay(
          `search_EQ_competitionId=${this.id}&search_LIKE_playerName=${this.playerName}&search_LIKE_groupCodeName=${this.groupCodeName}&search_EQ_teamId=${this.teamName}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.ToBePaid {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .main-card {
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
